import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";
import { showToast } from "@/constant/toast";
import { calculateSHA256, getVideoDuration } from "@/utils/helperFunction";
import axios from "axios";

export default {
    state: {
        currentMediaIds: [],
        uploadProgress: {},
        uploadSuccess: false,
        uploadFailed: false,
        videosStorageExceeded: false,
    },
    getters: {
        getCurrentMediaIds: (state) => state.currentMediaIds,
        getUploadProgress: (state) => (fileName) =>
            state.uploadProgress[fileName] || {
                progress: 0,
                status: "Pending",
            },
        getUploadSuccess: (state) => state.uploadSuccess,
        getUploadFailed: (state) => state.uploadFailed,
        getVideosStorageExceeded: (state) => state.videosStorageExceeded,
    },
    mutations: {
        SET_CURRENT_VIDEO_DATA(state, videosStorageExceeded) {
            state.videosStorageExceeded = videosStorageExceeded;
        },
        SET_CURRENT_MEDIA_ID(state, MediaId) {
            state.currentMediaIds.push(MediaId);
        },
        SET_UPLOAD_PROGRESS(state, { fileName, progress, status }) {
            state.uploadProgress[fileName] = { progress, status };
        },
        RESET_UPLOAD_PROGRESS(state) {
            state.uploadProgress = {};
            state.uploadFailed = false;
        },
        SET_UPLOAD_SUCCESS(state, status) {
            state.uploadSuccess = status;
        },
        SET_UPLOAD_FAILED(state, status) {
            state.uploadFailed = status;
        },
        RESET_MEDIA_IDS(state) {
            state.currentMediaIds = [];
        }
    },
    actions: {
        async getPresignedUrl({ commit }, data) {
            try {
                const feature = "media";
                const url = `/organizations/${StorageUtil.getLocalData(
                    "currentOrganization"
                )}/media/preSignedURL`;

                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    {}
                );

                console.log(response, "response for presigned url");

                const { Url, MediaId, videosStorageExceeded, errorMsg } =
                    response.data;
                commit("SET_CURRENT_VIDEO_DATA", videosStorageExceeded);

                return {
                    Url,
                    MediaId,
                    videosStorageExceeded,
                };
            } catch (error) {
                console.error(error);
                commit("SET_UPLOAD_PROGRESS", {
                    fileName: file.name,
                    progress: 0,
                    status: "Failed",
                });
                commit("SET_UPLOAD_SUCCESS", false);
                throw error;
            }
        },
        async SaveMediaFileStatus({ commit }, data) {
            try {
                const feature = "media";
                const url = `/organizations/${StorageUtil.getLocalData(
                    "currentOrganization"
                )}/media/updateStatus`;

                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    {}
                );

                console.log("Media status updated successfully:", response);

                return response;
            } catch (error) {
                console.error("Failed to update media status:", error);
                commit("SET_UPLOAD_PROGRESS", {
                    fileName: file.name,
                    progress: 0,
                    status: "Failed",
                });
                commit("SET_UPLOAD_SUCCESS", false);
                throw error;
            }
        },
        async uploadFile(
            { commit, dispatch },
            { file, displayName, description, mediaUploadType }
        ) {
            try {
                commit("SET_UPLOAD_SUCCESS", false);
                commit("SET_UPLOAD_FAILED", false);

                // Step 1: Generate Metadata
                const mediaSHA256 = await calculateSHA256(file);

                let metadata;
                if (mediaUploadType) {
                    metadata = {
                        MediaName: file.name,
                        MediaSize: file.size,
                        MediaDuration: file.duration,
                        MediaContentType: file.type,
                        MediaSHA256: mediaSHA256,
                        IsLocal: mediaUploadType,
                    };
                } else {
                    metadata = {
                        DisplayName: displayName,
                        MediaDescription: description,
                        MediaName: file.name,
                        MediaSize: file.size,
                        MediaDuration: file.duration,
                        MediaContentType: file.type,
                        MediaSHA256: mediaSHA256,
                        IsLocal: mediaUploadType,
                    };
                }

                console.log("Upload Payload:", metadata);

                // Step 2: Fetch Pre-signed URL and MediaId
                const { Url: preSignedURL, MediaId } = await dispatch(
                    "getPresignedUrl",
                    metadata
                );
                
                commit("SET_CURRENT_MEDIA_ID", MediaId);

                // Step 3: Handle Upload Progress
                await axios.put(preSignedURL, file, {
                    headers: {
                        "Content-Type": file.type,
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        commit("SET_UPLOAD_PROGRESS", {
                            fileName: file.name,
                            progress: percentCompleted,
                            status: "Uploading",
                        });
                    },
                });

                commit("SET_UPLOAD_PROGRESS", {
                    fileName: file.name,
                    progress: 100,
                    status: "Success",
                });

                commit("SET_UPLOAD_SUCCESS", true);

                showToast(
                    "success",
                    "Upload Complete",
                    `${file.name} uploaded successfully.`
                );

                return { MediaId };
            } catch (error) {
                console.error(`Upload failed for file ${file.name}`, error);
                commit("SET_UPLOAD_PROGRESS", {
                    fileName: file.name,
                    progress: 0,
                    status: "Failed",
                });
                commit("SET_UPLOAD_SUCCESS", false);
                commit("SET_UPLOAD_FAILED", true);
                throw error;
            }
        },
    },
};
