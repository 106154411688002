import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";
import { showToast } from "@/constant/toast";

export default {
    state: {
        newsEventList: [],
        WorldStories: [],
        filterData: [],
        fetchClientNewsSuccess: false,
        fetchFilterDataSuccess: false,
        filterNewsSuccess: false,
        deleteFilterSuccess: false,
        deleteFilterSuccess: false,
        updateFiltersSuccess: false,
        loadingStatus: false,
    },
    getters: {
        getNewsEvents: (state) => state.newsEventList,
        getWorldStories: (state) => state.WorldStories,
        getFilterData: (state) => state.filterData,
        getFetchClientNewsSuccess: (state) => state.fetchClientNewsSuccess,
        getFilterNewsSuccess: (state) => state.filterNewsSuccess,
        getFetchFilterDataSuccess: (state) => state.fetchFilterDataSuccess,
        getLoadingStatus: (state) => state.loadingStatus,
        getDeleteFilterSuccess: (state) => state.deleteFilterSuccess,
        getUpdateFiltersSuccess: (state) => state.updateFiltersSucces,
    },
    mutations: {
        GET_WORLD_STORIES(state, data) {
            state.WorldStories = data;
        },
        NEWS_EVENT_LIST(state, data) {
            state.newsEventList = data;
        },
        SET_FILTER_DATA(state, data) {
            state.filterData = data;
        },
        SET_FETCH_CLIENT_NEWS_SUCCESS(state, status) {
            state.fetchClientNewsSuccess = status;
        },
        SET_FILTER_NEWS_SUCCESS(state, status) {
            state.filterNewsSuccess = status;
        },
        SET_FETCH_FILTER_DATA_SUCCESS(state, status) {
            state.fetchFilterDataSuccess = status;
        },
        SET_DELETE_FILTER_SUCCESS(state, status) {
            state.deleteFilterSuccess = status;
        },
        SET_UPDATE_FILTERS_SUCCESS(state, status) {
            state.updateFiltersSuccess = status;
        },
        SET_LOADING_STATUS(state, status) {
            state.loadingStatus = status;
        },
    },
    actions: {
        async fetchClientNews({ commit }) {
            commit("SET_LOADING_STATUS", true);
            const feature = "news";
            const url = `clientnews?MainClientId=${StorageUtil.getLocalData(
                "currentOrganization"
            )}&EndpointType=q&MaximumArticles=100&specialt=8W1ZzUj-1xpYlUgI1BwgU`;

            try {
                const response = await RequestGateway.get(feature, url, {});
                commit("NEWS_EVENT_LIST", response);
                commit("SET_FETCH_CLIENT_NEWS_SUCCESS", true);
            } catch (error) {
                commit("SET_FETCH_CLIENT_NEWS_SUCCESS", false);
                console.error("Error fetching client news:", error);
            } finally {
                commit("SET_LOADING_STATUS", false);
            }
        },
        async fetchWorldStories({ commit }) {
            const feature = "news";
            const url = `worldnews?specialt=8W1ZzUj-1xpYlUgI1BwgU`;

            try {
                const response = await RequestGateway.get(feature, url, {});

                commit("GET_WORLD_STORIES", response);
            } catch (error) {
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async filterNews({ commit }, data) {
            commit("SET_LOADING_STATUS", true);
            const feature = "news";
            const url = `createFilter?MainClientId=${StorageUtil.getLocalData(
                "currentOrganization"
            )}&specialt=8W1ZzUj-1xpYlUgI1BwgU`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    {}
                );
                if (response) {
                    commit("SET_FILTER_NEWS_SUCCESS", true);
                }
            } catch (error) {
                commit("SET_FILTER_NEWS_SUCCESS", false);
                console.error("Error creating news filter:", error);
                return error;
            } finally {
                commit("SET_LOADING_STATUS", false);
            }
        },
        async fetchFilterData({ commit }) {
            commit("SET_LOADING_STATUS", true);
            const feature = "news";
            const url = `newsFilters?MainClientId=${StorageUtil.getLocalData(
                "currentOrganization"
            )}&specialt=8W1ZzUj-1xpYlUgI1BwgU`;

            try {
                const response = await RequestGateway.get(feature, url, {});
                commit("SET_FILTER_DATA", response);
                commit("SET_FETCH_FILTER_DATA_SUCCESS", true);
            } catch (error) {
                commit("SET_FETCH_FILTER_DATA_SUCCESS", false);
                console.error("Error fetching filter data:", error);
                return error;
            } finally {
                commit("SET_LOADING_STATUS", false);
            }
        },
        async deleteFilter({ commit }, recordId) {
            const feature = "news";
            const url = `deleteFilter?MainClientId=${StorageUtil.getLocalData(
                "currentOrganization"
            )}&RecordTypeId=${recordId}&specialt=8W1ZzUj-1xpYlUgI1BwgU`;

            try {
                const response = await RequestGateway.delete(feature, url, {});
                commit("SET_DELETE_FILTER_SUCCESS", true);
                return response;
            } catch (error) {
                commit("SET_DELETE_FILTER_SUCCESS", false);
                return error;
            }
        },
        async updateFilters({ commit }, data) {
            const feature = "news";
            const url = `createFilter?MainClientId=${StorageUtil.getLocalData(
                "currentOrganization"
            )}&specialt=8W1ZzUj-1xpYlUgI1BwgU`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    {}
                );
            } catch (error) {
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
    },
};
