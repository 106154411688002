import { createStore } from "vuex";
import news from "./app/news";
import users from "./app/users";
import roles from "./app/roles";
import report from "./app/report";
import analysis from "./app/analysis";
import dashboard from "./app/dashboard";
import subscription from "./app/subscription";
import media from "./app/media";
import StorageUtil from "@/utils/StorageUtil";
import { RequestGateway } from "@/store/RequestGateway";
import { showToast } from "@/constant/toast";

export default createStore({
    state: {
        sidebarCollasp: false,
        sidebarHidden: false,
        mobielSidebar: false,
        semidark: false,
        semiDarkTheme: "semi-light",
        isDark: true,
        skin: "default",
        theme: "dark",
        isOpenSettings: false,
        cWidth: "full",
        menuLayout: "vertical",
        navbarType: "sticky",
        footerType: "static",
        chartColors: {
            title: "red",
        },
        sessionStreamList: [],
        isTableLoading: false,
        streamLibraryList: [],
        scenarioObjectList: [],
        anomaliesList: [],
        mainClientList: [],
        selectedVideoIntSummary: null,
        deviceToken: "",
        role: StorageUtil.getLocalData("role") || "",
        firstName: StorageUtil.getLocalData("firstName") || "",
        userId: "",
        isSuperAdmin: StorageUtil.getLocalData("isSuperAdmin") || false,
        subscriptionType: StorageUtil.getLocalData("subscriptionType") || "",
        successEmailSupportFlag: false,
        successCreatePasswordFlag: false,
    },
    getters: {
        theme: (state) => state.theme,
        skin: (state) => state.skin,
        isTableLoading: (state) => state.isTableLoading,
        getMainClientList: (state) => state.mainClientList,
        getSelectedVideoIntSummary: (state) => state.selectedVideoIntSummary,
        getDeviceToken: (state) => state.deviceToken,
        role: (state) => state.role,
        firstName: (state) => state.firstName,
        userId: (state) => state.userId,
        isAdmin: (state) => state.isSuperAdmin,
        getSubscriptionType: (state) => state.subscriptionType,
        getSuccessEmailSupportFlag: (state) => state.successEmailSupportFlag,
        getSuccessCreatePasswordFlag: (state) =>
            state.successCreatePasswordFlag,
    },
    mutations: {
        setSidebarCollasp(state) {
            state.sidebarCollasp = !state.sidebarCollasp;
        },
        SET_ROLES(state, role) {
            state.role = role;
            StorageUtil.setLocalData("role", role);
        },
        SET_SUPER_ADMIN(state, data) {
            state.isSuperAdmin = data;
            StorageUtil.setLocalData("isSuperAdmin", data);
        },
        SET_USER_ID(state, userId) {
            state.userId = userId;
        },
        SET_FIRSTNAME(state, firstName) {
            state.firstName = firstName;
            StorageUtil.setLocalData("firstName", firstName);
        },
        toogleDark(state) {
            state.isDark = !state.isDark;
            state.theme = state.theme === "dark" ? "light" : "dark";
            document.body.classList.toggle(state.theme);
            localStorage.setItem("theme", state.theme);
        },
        toggleSettings(state) {
            state.isOpenSettings = !state.isOpenSettings;
        },
        toggleMsidebar(state) {
            state.mobielSidebar = !state.mobielSidebar;
        },
        toggleSemiDark(state) {
            state.semidark = !state.semidark;
            state.semiDarkTheme = state.semidark ? "semi-dark" : "semi-light";
            document.body.classList.toggle(state.semiDarkTheme);
            localStorage.setItem("semiDark", state.semidark);
        },
        CHANGE_LOADING_STATUS(state, value) {
            state.isTableLoading = value;
        },
        MAIN_CLIENT_LIST(state, data) {
            state.mainClientList = data;
        },
        SELETED_VIDEO_INT_SUMMARY(state, data) {
            state.selectedVideoIntSummary = data;
        },
        SET_DEVICE_TOKEN(state, val) {
            state.deviceToken = val;
        },
        SET_SUBSCRIPTION_TYPE(state, type) {
            state.subscriptionType = type;
            StorageUtil.setLocalData("subscriptionType", type);
        },
        SET_SUCCESS_EMAIL_SUPPORT_FLAG(state, flag) {
            state.successEmailSupportFlag = flag;
        },
        SET_SUCCESS_CREATE_PASSWORD_FLAG(state, flag) {
            state.successCreatePasswordFlag = flag;
        },
    },
    actions: {
        toogleDark({ commit }) {
            commit("toogleDark");
        },
        toggleSettings({ commit }) {
            commit("toggleSettings");
        },
        setSidebarCollasp({ commit }) {
            commit("setSidebarCollasp");
        },
        toggleMsidebar({ commit }) {
            commit("toggleMsidebar");
        },
        toggleSemiDark({ commit }) {
            commit("toggleSemiDark");
        },
        async setUserLogin({ commit }, data) {
            const feature = "inhouse";
            const url = `/login`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    {}
                );
                if (response.success === true) {
                    showToast("success", "Success", "Login uccessfully");
                    return response;
                }
            } catch (error) {
                return error;
            }
        },
        async updatePassword({ commit }, payload) {
            const feature = "inhouse";
            const url = `/change-password`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    {}
                );
                return response;
            } catch (error) {
                return error;
            }
        },
        async forgotPassword({ commit }, payload) {
            const feature = "inhouse";
            const url = `/forgot-password`;
            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    {}
                );
                if (response.success === true) {
                    showToast("success", "Success", "Email send successfully");
                    return response;
                }
            } catch (error) {
                return error;
            }
        },
        async emailSupport({ commit }, payload, userId) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getLocalData(
                "currentOrganization"
            )}/users/${StorageUtil.getLocalData("userId")}/help`;
            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    {}
                );
                if (response.success === true) {
                    showToast("success", "Success", "Email send successfully");
                    commit("SET_SUCCESS_EMAIL_SUPPORT_FLAG", true);
                    return response;
                }
            } catch (error) {
                commit("SET_SUCCESS_EMAIL_SUPPORT_FLAG", false);
                return error;
            }
        },
        async createPassword({ commit }, payload) {
            const feature = "inhouse";
            const url = "/v1/change-password";
            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    {}
                );
                if (response.success === true) {
                    showToast(
                        "success",
                        "Success",
                        "Password set successfully"
                    );
                    commit("SET_SUCCESS_CREATE_PASSWORD_FLAG", true);
                    return response;
                }
            } catch (error) {
                commit("SET_SUCCESS_CREATE_PASSWORD_FLAG", false);
                return error;
            }
        },
    },
    modules: {
        news,
        users,
        roles,
        report,
        analysis,
        dashboard,
        subscription,
        media,
    },
});
