import "animate.css";
import "flatpickr/dist/flatpickr.css";
import "simplebar-vue/dist/simplebar-vue.js";
import "simplebar/dist/simplebar.min.css";
import { createApp } from "vue";
import VueApexCharts from "vue3-apexcharts";
import App from "./App.vue";
import "./assets/scss/auth.scss";
import "./assets/scss/tailwind.scss";
import router from "./router";
import store from "./store";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import "primeicons/primeicons.css";
import ToastService from "primevue/toastservice";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import IftaLabel from "primevue/iftalabel";
import Select from "primevue/select";
import Avatar from "primevue/avatar";
import Fluid from "primevue/fluid";
import FocusTrap from "primevue/focustrap";
import Textarea from "primevue/textarea";
import Card from "primevue/card";
import FileUpload from "primevue/fileupload";
import ProgressBar from "primevue/progressbar";
import Chip from "primevue/chip";
import Listbox from "primevue/listbox";
import DataView from "primevue/dataview";
import Skeleton from "primevue/skeleton";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Row from "primevue/row";
import Dialog from "primevue/dialog";
import Toast from "primevue/toast";
import Tooltip from "primevue/tooltip";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";
import Tag from "primevue/tag";
import ScrollTop from "primevue/scrolltop";
import ScrollPanel from "primevue/scrollpanel";
import Stepper from "primevue/stepper";
import StepList from "primevue/steplist";
import StepPanels from "primevue/steppanels";
import StepItem from "primevue/stepitem";
import Step from "primevue/step";
import StepPanel from "primevue/steppanel";
import Divider from "primevue/divider";
import RadioButton from "primevue/radiobutton";
import Checkbox from "primevue/checkbox";
import PickList from "primevue/picklist";
import { setToastInstance } from "@/constant/toast";
import AnimateOnScroll from "primevue/animateonscroll";
import Drawer from 'primevue/drawer';
import Ripple from 'primevue/ripple';
import ToggleSwitch from 'primevue/toggleswitch';
import Image from 'primevue/image';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import Knob from 'primevue/knob';


// check localStorage theme for dark light bordered
if (localStorage.theme === undefined) {
    localStorage.setItem("theme", "dark");
}
if (localStorage.theme === "dark") {
    document.body.classList.add("dark");
    store.state.theme = "dark";
    store.state.isDark = true;
} else {
    document.body.classList.add("light");
    store.state.theme = "light";
    store.state.isDark = false;
}
if (localStorage.semiDark === "true") {
    document.body.classList.add("semi-dark");
    store.state.semidark = true;
    store.state.semiDarkTheme = "semi-dark";
} else {
    document.body.classList.add("semi-light");
    store.state.semidark = false;
    store.state.semiDarkTheme = "semi-light";
}
// check loacl storege for menuLayout
if (localStorage.menuLayout === "horizontal") {
    store.state.menuLayout = "horizontal";
} else {
    store.state.menuLayout = "vertical";
}

// check skin  for localstorage
if (localStorage.skin === "bordered") {
    store.state.skin = "bordered";
    document.body.classList.add("skin--bordered");
} else {
    store.state.skin = "default";
    document.body.classList.add("skin--default");
}


function loadGoogleMapsApi(apiKey) {
    return new Promise((resolve, reject) => {
        if (
            typeof window.google === "object" &&
            typeof window.google.maps === "object"
        ) {
            resolve();
            return;
        }

        const script = document.createElement("script");

        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        script.defer = true;

        script.onload = () => {
            resolve();
        };

        script.onerror = (error) => {
            reject(error);
        };

        document.head.appendChild(script);
    });
}

// Load the Google Maps API with your API key
loadGoogleMapsApi(process.env.VUE_APP_GOOGLE_MAPS_PLACE_KEY)
    .then(() => {
        // Initialize the Vue app and use plugins
        const app = createApp(App);

        app.use(store)
            .use(router)
            .use(VueApexCharts)
            .use(PrimeVue, {
                theme: {
                    preset: Aura,
                    options: {
                        darkModeSelector: true 
                    }
                },
                ripple: true
            })
            .use(ToastService)
            .use(ConfirmationService)
            .component("Button", Button)
            .component("InputText", InputText)
            .component("FloatLabel", FloatLabel)
            .component("IconField", IconField)
            .component("InputIcon", InputIcon)
            .component("IftaLabel", IftaLabel)
            .component("Select", Select)
            .component("Avatar", Avatar)
            .component("Fluid", Fluid)
            .component("Textarea", Textarea)
            .component("Card", Card)
            .component("FileUpload", FileUpload)
            .component("ProgressBar", ProgressBar)
            .component("Chip", Chip)
            .component("Listbox", Listbox)
            .component("DataView", DataView)
            .component("Skeleton", Skeleton)
            .component("DataTable", DataTable)
            .component("Column", Column)
            .component("Row", Row)
            .component("Toast", Toast)
            .component("ConfirmDialog", ConfirmDialog)
            .component("Dialog", Dialog)
            .component("Tag", Tag)
            .component("ScrollTop", ScrollTop)
            .component("ScrollPanel", ScrollPanel)
            .component("Stepper", Stepper)
            .component("StepList", StepList)
            .component("StepPanels", StepPanels)
            .component("StepItem", StepItem)
            .component("Step", Step)
            .component("StepPanel", StepPanel)
            .component("RadioButton", RadioButton)
            .component("Divider", Divider)
            .component("Checkbox", Checkbox)
            .component("PickList", PickList)
            .component("Drawer", Drawer)
            .component("ToggleSwitch", ToggleSwitch)
            .component("Image", Image)
            .component("Tabs",Tabs)
            .component("Tab",Tab)
            .component("TabList",TabList)
            .component("TabPanels",TabPanels)
            .component("TabPanel",TabPanel)
            .component("Knob",Knob)
            .directive('ripple', Ripple)
            .directive("AnimateOnScroll", AnimateOnScroll)
            .directive("focustrap", FocusTrap)
            .directive("tooltip", Tooltip)
            .mount("#app");
        setToastInstance(app.config.globalProperties.$toast);
    })
    .catch((error) => {
        console.error("Error loading Google Maps API:", error);
    });
