import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";

export default {
    state: {
        activeSubscriptionDetails: {},
        activePlanDetails: {},
        planDetails: {},
        summaryDeatils: {},
        topUpDeatils: [],
        subcriptionSummaryLoading: true,
        cancelSucriptionSuccess: false,
        upgradeSubscriptionSuccess: false,
        fetchTopUpSuccess: false,
        addTopUpSuccess: false,
    },
    getters: {
        getActiveSubscriptionDetails(state) {
            return state.activeSubscriptionDetails;
        },
        getActivePlanDetails(state) {
            return state.activePlanDetails;
        },
        getPlanDetails(state) {
            return state.planDetails;
        },
        getSummaryDetails(state) {
            return state.summaryDeatils;
        },
        getSummaryLoading(state) {
            return state.subcriptionSummaryLoading;
        },
        getCancelSubcriptionSuccess: (state) => state.cancelSucriptionSuccess,
        getUpgradeSubscriptionSuccess: (state) =>
            state.upgradeSubscriptionSuccess,
        getTopUpDetails(state) {
            return state.topUpDeatils;
        },
        getFetchTopUpSuccess(state) {
            return state.fetchTopUpSuccess;
        },
        getAddTopUpSuccess(state) {
            return state.addTopUpSuccess;
        },
    },
    mutations: {
        SET_ACTIVE_SUBSCRIPTION_DETAILS(state, activeSubscriptionDetails) {
            state.activeSubscriptionDetails = activeSubscriptionDetails;
        },
        SET_ACTIVE_PLAN_DETAILS(state, activePlanDetails) {
            state.activePlanDetails = activePlanDetails;
        },
        SET_PLAN_DETAILS(state, planDetails) {
            state.planDetails = planDetails;
        },
        SET_SUMAARY_DETAILS(state, summaryDeatils) {
            state.summaryDeatils = summaryDeatils;
        },
        SET_SUBCRIPTION_SUMMARY_LOADING(state, summaryFlag) {
            state.subcriptionSummaryLoading = summaryFlag;
        },
        SET_CANCEL_SUBSCRIPTION_SUCCESS(state, status) {
            state.cancelSucriptionSuccess = status;
        },
        SET_UPGRADE_SUBSCRIPTION_SUCCESS(state, status) {
            state.upgradeSubscriptionSuccess = status;
        },
        SET_TOPUP_DETAILS(state, data) {
            state.topUpDeatils = data;
        },
        SET_FETCH_TOPUP_SUCCESS(state, status) {
            state.fetchTopUpSuccess = status;
        },
        SET_ADD_TOPUP_SUCCESS(state, status) {
            state.addTopUpSuccess = status;
        },
    },
    actions: {
        async fetchBillingSummary({ commit }) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getLocalData(
                "currentOrganization"
            )}/billing/summary`;
            try {
                const response = await RequestGateway.get(feature, url, {});
                if (response) {
                    commit("SET_SUMAARY_DETAILS", response);
                    return response;
                }
            } catch (error) {
                commit("SET_SUBCRIPTION_SUMMARY_LOADING", false);
                return error;
            } finally {
                commit("SET_SUBCRIPTION_SUMMARY_LOADING", false);
            }
        },
        async cancelSubscription({ commit }, payload) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getLocalData(
                "currentOrganization"
            )}/subscriptions/cancel`;
            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    {}
                );
                commit("SET_CANCEL_SUBSCRIPTION_SUCCESS", true);
                return response;
            } catch (error) {
                commit("SET_CANCEL_SUBSCRIPTION_SUCCESS", false);
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchPlans({ commit }, param) {
            const feature = "subscription";
            const url = `/api/v1/plans?isYearly=${param}`;
            try {
                const response = await RequestGateway.get(feature, url, {});

                commit("SET_PLAN_DETAILS", response.SubscriptionPlans);
                return response;
            } catch (error) {
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async upgradeSubcription({ commit }, payload) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getLocalData(
                "currentOrganization"
            )}/subscriptions/upgrade`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    {}
                );

                if (response.Success === true) {
                    commit("SET_UPGRADE_SUBSCRIPTION_SUCCESS", true);
                }
                return response;
            } catch (error) {
                commit("SET_UPGRADE_SUBSCRIPTION_SUCCESS", false);
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchTopUp({ commit }, param) {
            const feature = "subscription";
            const url = `/api/v1/topup/plans?type=${param}`;
            try {
                const response = await RequestGateway.get(feature, url, {});

                commit("SET_TOPUP_DETAILS", response.Price[0]);
                commit("SET_FETCH_TOPUP_SUCCESS", true);

                return response;
            } catch (error) {
                commit("SET_FETCH_TOPUP_SUCCESS", false);
                return error;
            }
        },
        async addTopUp({ commit }, payload) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getLocalData(
                "currentOrganization"
            )}/subscriptions/topup`;
            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload
                );
                if (response.Success === true) {
                    commit("SET_ADD_TOPUP_SUCCESS", true);
                }
                return response;
            } catch (error) {
                commit("SET_ADD_TOPUP_SUCCESS", false);
                return error;
            }
        },
    },
};
